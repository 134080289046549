<template>
    <div class="verifiers">
        <div v-if="dataLoadingActive" class="data-loading text-center">
            <i class="fa fa-spinner fa-pulse fa-fw"></i>
            <span class="sr-only">Загрузка...</span>
        </div>

        <div v-if="dataLoadingError" class="alert alert-danger" role="alert">
            Произошла ошибка загрузки данных. Попробуйте обновить страницу.
        </div>

        <div v-if="!dataLoadingActive && !dataLoadingError">
            <!--Поиск-->
            <div v-if="verifiers.length > 3">
                <div class="row my-5">
                    <label for="inputSearch" class="col-sm-2 col-form-label">
                        <i class="fas fa-search"></i> Найти поверителя
                    </label>
                    <div class="col-sm-10">
                        <input
                            v-model="searchText"
                            type="search"
                            class="form-control"
                            id="inputSearch"
                            placeholder="Введие город или наименование компании">

                        <div v-if="getPopularCities.length > 2" class="text-muted">
                            Например:
                            <button
                                v-for="city in getPopularCities"
                                v-on:click="searchText = city.name"
                                class="btn btn-sm btn-link"
                            >
                                {{ city.name }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="searchText.trim() !== ''" class="verifiers-header">
                По запросу "{{ searchText }}" найдено поверителей:
                <span class="verifiers-count">{{ getFilteredVerifiers.length }}</span>
                из <button v-on:click="searchText = ''" class="btn btn-light">{{ verifiers.length }}</button>
            </div>
            <div v-else class="verifiers-header">
                Поверителей:
                <span class="verifiers-count">{{ verifiers.length }}</span>
            </div>

            <div v-for="verifier in getVisibleVerifiers" class="verifier row" v-bind:class="{ 'verifier-special-price': verifier.special }">
                <div class="col-lg-8 col-12">
                    <div class="name">
                        <a v-bind:href="'/companies/' + verifier.url" target="_blank">
                            <text-highlight
                                :queries="searchText"
                                highlightClass="highlight">
                                {{ verifier.name }}
                            </text-highlight>
                        </a>
                    </div>
                    <div class="br">
                        <span v-if="verifier.br_ogrn">ОГРН: {{ verifier.br_ogrn }}</span>
                        <span v-if="verifier.br_inn">ИНН: {{ verifier.br_inn }}</span>
                        <span v-if="verifier.br_kpp">КПП: {{ verifier.br_kpp }}</span>
                    </div>
                    <div class="contacts">
                        <span v-if="verifier.phone_vri" class="phone"><i class="fa fa-phone contact-icon" aria-hidden="true"></i> {{ verifier.phone_vri }}</span>
                        <span v-else-if="verifier.phone_main" class="phone"><i class="fa fa-phone contact-icon" aria-hidden="true"></i> {{ verifier.phone_main }}</span>
                        <span v-else-if="verifier.phone_fgis" class="phone"><i class="fa fa-phone contact-icon" aria-hidden="true"></i> {{ verifier.phone_fgis }}*</span>

                        <span v-if="verifier.mail_vri" class="mail"><i class="fa fa-envelope contact-icon" aria-hidden="true"></i> {{ verifier.mail_vri }}</span>
                        <span v-else-if="verifier.mail_main" class="mail"><i class="fa fa-envelope contact-icon" aria-hidden="true"></i> {{ verifier.mail_main }}</span>
                        <span v-else-if="verifier.mail_fgis" class="mail"><i class="fa fa-envelope contact-icon" aria-hidden="true"></i> {{ verifier.mail_fgis }}*</span>
                    </div>
                    <div class="address">
                        <span v-if="verifier.address_vri" class="addr"><i class="fa fa-map-marker contact-icon" aria-hidden="true"></i> {{ verifier.address_vri }}</span>
                        <span v-else-if="verifier.address_fact" class="addr"><i class="fa fa-map-marker contact-icon" aria-hidden="true"></i> {{ verifier.address_fact }}</span>
                        <span v-else-if="verifier.address_fgis" class="addr"><i class="fa fa-map-marker contact-icon" aria-hidden="true"></i> {{ verifier.address_fgis }}*</span>
                    </div>
                </div>
                <div class="col-lg-3 col-6 align-self-center">
                    <template v-if="verifier.price_min || verifier.special">
                        <div class="price-str">Цена поверки</div>
                        <!-- Если есть уточняющие строки -->
                        <div v-if="verifier.lines_count>1">
                            <div v-on:click="verifier.open_lines = !verifier.open_lines">
                                <span class="price-value-link">
                                  <!-- Если есть разные цены, то добавляем приставку "ОТ" -->
                                  <template v-if="verifier.price_min != verifier.price_max">от</template>
                                  {{ Number(verifier.price_min).toFixed(2) }}₽
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <span class="price-value">
                                <template v-if="verifier.price_min">
                                    от {{ Number(verifier.price_min).toFixed(2) }}₽
                                  <div class="text-muted fs-6" v-if="verifier.company_id == 13904">
                                    При большом объеме
                                  </div>
                                </template>
                                <template v-else-if="verifier.company_id == 9026">
                                    1200р. за канал
                                </template>
                                <template v-else="verifier">
                                    Уточняйте
                                </template>
                            </span>
                        </div>
                    </template>

                    <!-- <div class="count-value">{{ verifier.lines_count }} - {{ verifier.open_lines }}</div> -->
                </div>
                <div class="col-lg-1 col-6 align-self-center text-right">
                    <template v-if="verifier.vri_summ">
                        <div class="count-str">Поверено</div>
                        <div class="count-value">{{ verifier.vri_summ }}</div>
                    </template>
                </div>
                <transition name="fade">
                    <div v-if="(verifier.lines_count > 1) && (verifier.open_lines)" class="col-12">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Пояснение</th>
                                    <th>Цена</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in verifier.lines">
                                    <td>{{ line.note}}</td>
                                    <td>{{ line.price}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center">
                            <button v-on:click="verifier.open_lines = false" class="btn btn-sm">Скрыть</button>
                        </div>
                    </div>
                </transition>
            </div>
            <!-- Страницы -->
            <nav v-if="getPages.length > 1" class="py-3" aria-label="Verifiers page navigation">
                <ul class="pagination">
                    <li v-for="page in getPages" class="page-item" :class="{ 'active' : page === pageNum}">
                        <button class="page-link shadow-none" v-on:click="pageNum = page" href="#">{{ page }}</button>
                    </li>
                </ul>
            </nav>

            <div class="alert alert-primary my-4" role="alert">
                <i class="fas fa-address-book"></i> <strong>Являетесь поверителем?</strong>
                По вопросам размещения компании в списке поверителей обращайтесь по адресу:
                <a href="mailto:admin@all-pribors.ru"><strong>admin@all-pribors.ru</strong></a>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import TextHighlight from 'vue-text-highlight';

    export default {
        data: () => ({
            verifiers: [],
            searchText: '',
            pageNum: 1,
            verifiersPerPage: 10,
            grsiDevice: {},
            dataLoadingActive: true,
            dataLoadingError: false
        }),

        props: ['device_id'],

        components: {
            TextHighlight
        },

        watch: {
            searchText: function () {
                console.log('Сброс номера страницы');
                this.pageNum = 1;
            },
        },

        computed: {
            getDeviceUpdatedAt: function () {
                let dt = new Date(this.grsiDevice.verifiers_updated_at);
                return dt.toLocaleDateString();
            },
            getPages: function () {
                const pages = [];
                const pagesCount = Math.ceil(this.getFilteredVerifiers.length / this.verifiersPerPage);

                for (let i=1; i<=pagesCount; i++) {
                    pages.push(i)
                }
                return pages;
            },
            getFilteredVerifiers: function () {
                if (this.searchText.trim() === '')
                    return this.verifiers;
                return this.verifiers.filter((verifier) => {
                    if (!verifier.name) {
                        return false;
                    }
                    return (verifier.name.toUpperCase().indexOf(this.searchText.trim().toUpperCase()) >= 0);
                })
            },
            getVisibleVerifiers: function () {
                return this.getFilteredVerifiers.slice((this.pageNum - 1) * this.verifiersPerPage, this.pageNum * this.verifiersPerPage);
            },
            getCities: function () {
                let cities = [];
                this.verifiers.forEach((verifier) => {
                    if (verifier.city_name) {
                        let city = cities.find((el) => { return el.id === verifier.city_id});
                        if(typeof city == 'undefined') {
                            let city_name = verifier.city_name;
                            if (city_name === 'Санкт-Петербург') {city_name = 'Петербург'}
                            cities.push({
                                id: verifier.city_id,
                                name: city_name,
                                count: 1
                            });
                        }
                        else {
                            city.count++;
                        }
                    }

                })
                return cities;
            },
            getPopularCities: function () {
                return this.getCities.sort((a, b) => {
                    return b.count - a.count;
                }).slice(0, 10);
            }
        },

        created() {
            this.dataLoadingActive = true;
            axios.get('/api/devices/'+this.device_id+'/verifiers')
                .then(response => {
                    // Предподготовка массива
                    this.verifiers = response.data.verifiers.map(function(ver) {
                        ver.open_lines = false;
                      return ver;
                    });
                    this.grsiDevice = response.data.grsiDevice;

                    this.dataLoadingError = false;
                    this.dataLoadingActive = false;
                })
                .catch(error => {
                    console.log(error);
                    this.dataLoadingError = true;
                    this.dataLoadingActive = false;
                });
        }
    }
</script>

<style lang="scss">
    .verifiers {
        margin: 20px 0px;

        .data-loading {
            padding: 2em;
            color: #c0171d;
            font-size: 3em;
        }

        .verifiers-header {
            color: #cccccc;
            font-size: 1.5em;

            .verifiers-count {
                color: rgb(242, 123, 53);
            }
        }


        .verifier {
            padding: 10px;
            margin: 10px 0px;
            -webkit-box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2);
            box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2);

            .name {
                font-size: 1.3em;
            }

            .br {
                font-size: 0.8em;
                color: #999999;
            }

            .contacts {
                padding: 8px 0px;
                font-size: 1.1em;

                .contact-icon {
                    color: #cccccc;
                }

                .phone {
                    color: rgb(242, 123, 53);
                }

                .mail {
                    color: rgb(242, 123, 53);
                }
            }

            .address {
                padding: 8px 0px;

                .contact-icon {
                    color: #cccccc;
                }

                /*.addr {
                    color: rgb(242, 123, 53);
                }*/
            }

            .count-str {
                font-size: 0.8em;
            }
            .count-value {
                font-size: 1.5em;
                color: #c0171d;
            }

            .price-str {
                font-size: 0.8em;
            }
            .price-value {
                font-size: 1.5em;
                color: #c0171d;
            }
            .price-value-link {
                cursor: pointer;
                display: inline-block;
                border-bottom: 2px dashed #c0171d;
                font-size: 1.5em;
                color: #c0171d;
            }

        }

        .verifier-special {background-color: #f9e7dd; padding-top: 20px; padding-bottom: 20px;} // Оплаченный блок спецзарзмещения
        .verifier-special-price {background-color: #ffecb4; padding-top: 20px; padding-bottom: 20px;} // Оплаченный блок спецзарзмещения
    }

    .highlight {
        padding: 0;

    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .8s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
      opacity: 0;
    }

    .bounce-enter-active {
      animation: bounce-in .5s;
    }
    .bounce-leave-active {
      animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.5);
      }
      100% {
        transform: scale(1);
      }
    }


</style>
