
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('../vendor/svg-with-js/js/fontawesome-all.min.js');

require('./bootstrap');

require('./utils/adv-scrollspy');

// Слайдер Slick carousel 2
require('slick-carousel');

//noUiSlider
import noUiSlider from 'nouislider';
// Двойной слайдер выбора диапазона лет
$(function() {
  // Собираем компоненты
  var year_range_slider = document.getElementById('year-range-slider');
  if (year_range_slider) {

    var year_range_min = document.getElementById('year-range-min');
    var year_range_max = document.getElementById('year-range-max');

    if (!year_range_min.value)
      year_range_min.value = year_range_slider.getAttribute("min");
    if (!year_range_max.value)
      year_range_max.value = year_range_slider.getAttribute("max");

    // Создаем слайдер
    noUiSlider.create(year_range_slider, {
        start: [year_range_min.value, year_range_max.value],
        connect: true,
        step: 1,
        range: {
            'min': Number(year_range_slider.getAttribute("min")),
            'max': Number(year_range_slider.getAttribute("max"))
        }
    });

    year_range_slider.noUiSlider.on('update', function (values, handle) {
      var value = values[handle];
      if (handle) {
          year_range_max.value = Math.round(value);
      } else {
          year_range_min.value = Math.round(value);
        }
    });

    year_range_min.addEventListener('change', function () {
      year_range_slider.noUiSlider.set([this.value, null]);
    });
    year_range_max.addEventListener('change', function () {
      year_range_slider.noUiSlider.set([null, this.value]);
    });
  }// if (year_range_slider)
});


// Selectize
require('../vendor/selectize.js-master/dist/js/standalone/selectize.js');
$(document).ready(function() {
    // Список выбора категорий
    $(".group-select").selectize({
          valueField: "id",
          labelField: "text",
          searchField: "text",
          create: false,
          preload: "focus",
          closeAfterSelect: true,
          plugins: ["remove_button"],
          loadThrottle: 100,

          load: function (query, callback) {
            //if (!query.length) return callback();
            //alert('load');
            $.ajax({
              url: "/api/selectize/groups?q=" + encodeURIComponent(query),
              type: "GET",
              error: function () {
                callback();
              },
              success: function (res) {
                //alert(res.results.slice(0, 10));
                callback(res.results);
              },
            });
          },
        });
    // Список выбора городов
    $(".city-select").selectize({
          valueField: "id",
          labelField: "text",
          searchField: "text",
          create: false,
          preload: "focus",
          closeAfterSelect: true,
          plugins: ["remove_button"],
          loadThrottle: 100,

          load: function (query, callback) {
            //if (!query.length) return callback();
            //alert('load');
            $.ajax({
              url: "/api/selectize/cities?q=" + encodeURIComponent(query),
              type: "GET",
              error: function () {
                callback();
              },
              success: function (res) {
                //alert(res.results.slice(0, 10));
                callback(res.results);
              },
            });
          },
        });
    // Список выбора компаний
    $(".company-select").selectize({
          valueField: "id",
          labelField: "text",
          searchField: "text",
          create: false,
          preload: "focus",
          closeAfterSelect: true,
          plugins: ["remove_button"],
          loadThrottle: 100,

          load: function (query, callback) {
            //if (!query.length) return callback();
            //alert('load');
            $.ajax({
              url: "/api/selectize/companies?q=" + encodeURIComponent(query),
              type: "GET",
              error: function () {
                callback();
              },
              success: function (res) {
                //alert(res.results.slice(0, 10));
                callback(res.results);
              },
            });
          },
        });
});

require('magnific-popup');
$(function(){
    $('.data-frame').magnificPopup({type: 'iframe'});
    $('.image-frame').magnificPopup({type:'image'});

    $('.js-pics-gallery').magnificPopup({
      delegate: 'a.js-gallery-item',
      type: 'image',
      tLoading: 'Загрузка #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1]
      },
      zoom: {
              enabled: true,
              duration: 300
            }
    });


  var galleryItems = [];
    $('.js-gallery-item').each(function () {
        galleryItems.push({src: $(this).prop("href")});
    });
    $('.js-gallery-show').magnificPopup({
      items: galleryItems,
      type: 'image',
          tLoading: 'Загрузка #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0,1]
        },
    });
});

// включаем подсказки tooltip (Bootstrap)
$(function(){
    // инициализации подсказок для всех элементов на странице, имеющих атрибут data-toggle="tooltip"
    $('[data-toggle="tooltip"]').tooltip();
});

// Навигация по хэшу адресной строки в табах (Tabs Bootstrap)
$(function() {
  if(window.location.hash){
    $('a[href="'+window.location.hash+'"]').trigger('click');
  }
});
$('body').on('click', 'a[data-toggle="tab"]', function(){
  //window.location.hash = $(this).attr('href');
  history.replaceState( {} , null , $(this).attr('href'));
})

$('body').on('click', 'a[data-toggle="show-verifiers"]', function(){
  $('#verifiers-tab').tab('show')
  $('body,html').animate({scrollTop: $('#verifiers-tab').offset().top-40});
})

// TODO: Проверить Scrollspy
// Скроллспай (Bootstrap)
// $('body').scrollspy({ target: '.list-contents', offset: 50 });
// Плавный переход в содержании (Скроллспай)
// $("body").on("click", ".list-contents a", function (event) {
//     event.preventDefault();
//     $('body,html').animate({scrollTop: $($(this).attr('href')).offset().top-40});
//   });


// Слайдер приборов
$('.devices-slider').slick({
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000
});

// Up To Top
var upToTop = $("#up-to-top");
var html_body = $('html, body');
upToTop.hide();
var offset = 300;
var duration = 500;
$(window).on('scroll', function () {
    if ($(this).scrollTop() > offset) {
        upToTop.fadeIn(duration);
    } else {
        upToTop.fadeOut(duration);
    }
});
upToTop.on('click', function (event) {
    event.preventDefault();
    html_body.animate({scrollTop: 0}, duration);
    return false;
});


// Кнопка разворачивания полного описания в GrsiDevice
$(function () {
  // Если описание длинное, сворачиваем его
  if ($('.full-description-content').height()>1000) {
    $('.full-description-content').css({"max-height": "500px"});
    $('.full-description-collapser').css({"display": "block"});
  }

  $('.js-show-full-description').click(function(){
        $('.full-description-content').animate({"max-height": "100000"}, 5000);
        $('.full-description-collapser').hide();
        return false;
    });
});


import Vue from 'vue';

// Компоненты
import grsiDeviceVerifiers from './components/Api/GrsiDeviceVerifiers.vue';
import configuratorStd from './components/Api/ConfiguratorStd.vue';

const app = new Vue({
    el: '#app',
    components: { grsiDeviceVerifiers, configuratorStd }
});
