<template>
  <div>
    <!--Описание к конфигуратору-->
    <div v-if="configuration.description" v-html="configuration.description" class="my-2"></div>
    <div class="alert"
         :class="{
          'alert-warning': getConfigurationStatusCode() === 2,
          'alert-danger': getConfigurationStatusCode() === 3,
          'alert-success': getConfigurationStatusCode() === 4,
        }"
    >
      <div class="h3">{{ orderCode }}</div>
      <div v-if="getConfigurationStatusCode() === 2" class="h6 mt-2">
        Элементы, отмеченные оранжевым цветом, обязательны к заполнению.
      </div>
      <div v-if="getConfigurationStatusCode() === 3" class="h6 mt-2">
        В конфигурации есть ошибки.
      </div>
      <div v-if="getConfigurationStatusCode() === 4" class="h6 mt-2">
        Код заказа сформирован.
      </div>
    </div>
    <!--Пояснение в верхней части списка параметров-->
    <div v-if="configuration.info_top" v-html="configuration.info_top" class="my-2"></div>
    <div>
      <div v-for="param in visibleParams" :key="param.id"
        class="my-2 alert border-top-0 border-end-0 border-bottom-0 border-5 bg-light"
        :class="{
          'alert-light': getParamStatusCode(param) === 1,
          'alert-warning': getParamStatusCode(param) === 2,
          'alert-danger': getParamStatusCode(param) === 3,
          'alert-success': getParamStatusCode(param) === 4,
        }"
      >
        <div class="row">
          <!-- Заголовок параметра -->
          <div class="col-4">
            {{ param.name }}
            <div v-if="param.note" class="text-muted small">{{ param.note }}</div>
          </div>
          <!-- Значения -->
          <div class="col-8">
            <!-- Список выбора -->
            <template v-if="param.type === 0">
              <select class="form-select" v-model="param.value" @change="getConfigurationData()">
                <option :value="null">Выберите...</option>
                <option v-for="value in param.values"
                        :key="value.id" v-bind:value="value.id"
                        :class="{'text-danger': configuration.valuesConflictList.includes(value.id)}"
                >
                  <template v-if="configuration.valuesConflictList.includes(value.id)">*</template>
                  [{{ value.code }}] {{ value.caption }}
                </option>
              </select>
              <button v-if="param.description && !param.description_visible"
                      @click="param.description_visible = true"
                      class="btn btn-link btn-sm float-end">
                Cправка
              </button>
              <div v-if="param.value" class="small text-muted my-1">{{ getParamValue(param.value).value.note }}</div>
              <div v-if="configuration.valuesConflictList.includes(param.value)">
                <div v-for="value in getConflictValues(param.value)" class="small my-1">
                  Невозможно с: <strong>{{ getParamValueHuman(value) }}</strong>
                </div>
              </div>
            </template>
            <!-- Текст -->
            <template v-else-if="param.type === 1">
              <textarea class="form-control" v-model="param.value"></textarea>
              <button v-if="param.description && !param.description_visible"
                      @click="param.description_visible = true"
                      class="btn btn-link btn-sm float-end">
                Cправка
              </button>
            </template>
            <!-- Строка -->
            <template v-else-if="param.type === 2">
              <input class="form-control" type="text" v-model="param.value">
              <button v-if="param.description && !param.description_visible"
                      @click="param.description_visible = true"
                      class="btn btn-link btn-sm float-end">
                Cправка
              </button>
              <div class="small" v-if="getParamStatusCode(param) === 3">
                Макс. кол-во символов: {{ param.max ? param.max : 255 }}
              </div>
            </template>
            <!-- Число -->
            <template v-else-if="param.type === 3">
              <input class="form-control" type="number" v-model="param.value" :min="param.min" :max="param.max">
              <button v-if="param.description && !param.description_visible"
                      @click="param.description_visible = true"
                      class="btn btn-link btn-sm float-end">
                Cправка
              </button>
              <div class="small">
                <span v-if="param.min">Мин: {{ param.min }}</span>
                <span v-if="param.max">Mакс: {{ param.max }}</span>
              </div>
            </template>
          </div>
        </div>
        <div v-if="param.description_visible"
          class="alert alert-light bg-white mt-3 mb-0">
          <button @click="param.description_visible = false" class="btn-close float-end" aria-label="Close"></button>
          <div v-html="param.description"></div>
        </div>
      </div>
    </div>
    <!-- Кнопки управления -->
    <div class="my-4">
      <button @click="saveConfiguration" type="button" class="btn btn-success">
        <i class="fa fa-share"></i> Сохранить
      </button>
      <button @click="downloadPdf" type="button" class="btn btn-secondary">
        <i class="fa fa-download"></i> Скачать PDF
      </button>
    </div>
    <!-- Сохраненный код -->
    <div v-if="savedByUserConfiguration" class="alert alert-primary" role="alert">
      <h4>Конфигурация сохранена:</h4>
      <div>
        <a :href="savedByUserConfigurationUrl" target="_blank">
          {{ savedByUserConfigurationUrl }}
        </a>
      </div>
    </div>
    <!-- Пояснение в нижней части списка параметров -->
    <div v-if="configuration.info_bottom" v-html="configuration.info_bottom" class="my-2"></div>
  </div>
</template>

<script>
// TODO: Адаптивность под мобильные устройства
// TODO: Исключения в кодах заказа
import axios from 'axios';

export default {
  data: () => ({
    configuration: {},
    openDescriptions: [],
    savedByUserConfiguration : null,
    savedByLinkConfiguration : null,
    dataLoading: false,
    dataLoadingError: false,
  }),

  props: ['configuratorUrl', 'saved'],

  methods: {
    /**
     * Возвращает список конфликтующих valueId по указанному valueId
     * @param valueId
     * @returns {*}
     */
    getConflictValues(valueId) {
      return this.configuration.valuesConflictPairsList.filter(conflict => conflict.value_1_id === valueId)
        .map(conflict => conflict.value_2_id)
        .concat(
          this.configuration.valuesConflictPairsList.filter(conflict => conflict.value_2_id === valueId)
            .map(conflict => conflict.value_1_id)
        );
    },
    /**
     * Возвращает статус-код для всей конфигурации.
     * 2 - Есть незаполненные обязательные параметры
     * 3 - Есть ошибки
     * 4 - ОК
     */
    getConfigurationStatusCode() {
      if (!this.configuration.hasOwnProperty('params')) {
        return '1';
      }
      // Проверка наличия ошибок
      const hasErrors = this.visibleParams.find(param => {
        return this.getParamStatusCode(param) === 3;
      })
      if (hasErrors !== undefined) {
        return 3;
      }
      // Проверка наличия обязательных параметров
      const hasRequired = this.visibleParams.find(param => {
        return this.getParamStatusCode(param) === 2;
      })
      if (hasRequired !== undefined) {
        return 2;
      }
      return 4;
    },
    /**
     * Возвращает статус-код для параметра.
     * 1 - Параметр является необязательным
     * 2 - Параметр обязателен
     * 3 - В параметре есть ошибка
     * 4 - ОК
     * @param param
     */
    getParamStatusCode(param) {
      if (!param.required && !param.value) {
        return 1;
      }
      if (param.required && !param.value) {
        return 2;
      }

      if (param.type === 0) { // select
        if (param.value && this.configuration.valuesConflictList.includes(param.value)) {
          return 3;
        }
      } else if (param.type === 3) { // number
        if (param.value && ((param.min && param.value < param.min) || (param.max && param.value > param.max))) {
          return 3;
        }
      } else if (param.type === 2) { // string
        let max = param.max || 255;
        if (param.value && (param.value.length > max)) {
          return 3;
        }
      }

      return 4;
    },
    /**
     * Возвращает пользовательский ввод в виде объекта
     * @returns {{[p: string]: any}|{}}
     */
    getUserInput() {
      if (this.savedByLinkConfiguration) {
        const savedInput = this.savedByLinkConfiguration.params;
        this.savedByLinkConfiguration = null
        return savedInput;
      }
      if (!this.configuration.hasOwnProperty('params')) {
        return {};
      }
      const userInput = Object.fromEntries(
        this.visibleParams
        .filter(param => param.value)
        .map(param => [param.id, param.value])
      )
      return userInput;
    },
    /**
     * Возвращает код выбранного пользователем значения параметра.
     * @param param
     *
     * @returns {string|*|string}
     */
    getParamCode(param) {
      function getPPCode(value) {
        const prefix = param.code_prefix ?? '';
        const postfix = param.code_postfix ?? '';
        return prefix + value + postfix;
      }

      if (!param.visible) {
        return '';
      }
      if ((param.type === 0) && param.value) {
        let result = param.values.find(value => (value.id === param.value))
        return result === undefined ? getPPCode('_') : getPPCode(result.code);
      } else if (param.type === 2 || param.type === 3) {
        return param.value ? getPPCode(param.value) : getPPCode('_');
      }
      return getPPCode('_');
    },
    /**
     * Возвращает пару объектов "Param" - "Value" по идентификатору value.
     * Используется для вывода информации о конфликтующем параметре, а также примечания к параметрам
     * @param valueId
     * @returns {{}|{param: *, value: *}}
     */
    getParamValue(valueId) {
      for (let param of this.configuration.params) {
        const value = param.values.find(value => {
          return value.id === valueId;
        })
        if (value) {
          return {
            param,
            value
          }
        }
      }
      return {};
    },
    /**
     * Возвращает человекопонятную строку с наименованием Параметра и значения по valueId.
     * Используется для вывода информации о конфликтующем параметре.
     * Основана на функции getParamValue.
     * @param valueId
     * @returns {string}
     */
    getParamValueHuman(valueId) {
      let paramValue = this.getParamValue(valueId);
      return `${paramValue.param.name} - ${paramValue.value.caption}`;
    },
    getConfigurationData() {
      this.dataLoading = true;
      axios.post(
        '/api/configurator/' + this.configuratorUrl,
        this.getUserInput()
      )
        .then(response => {
          let configuration = response.data;
          configuration.params = configuration.params.map(function(param) {
            if (!Object.hasOwn(param, 'value')) {
              param.value = null
            }
            return param;
          })
          this.configuration = configuration;
          console.log('Конфигурация:', this.configuration)
          this.dataLoadingError = false;
          this.dataLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.dataLoadingError = true;
          this.dataLoading = false;
        });
    },
    saveConfiguration(download = false) {
      axios.post(
        '/api/configurator/' + this.configuratorUrl + '/save',
        this.getUserInput())
        .then(response => {
          this.savedByUserConfiguration = response.data;
        })
        .catch(error => {
          alert('Ошибка сохранения');
          console.log(error);
          this.dataLoadingError = true;
          this.dataLoading = false;
        });
    },
    downloadPdf() {
      axios.post(
        '/api/configurator/' + this.configuratorUrl + '/save',
        this.getUserInput())
        .then(response => {
          this.savedByUserConfiguration = response.data;
          window.open('/configurator/download/' + this.savedByUserConfiguration.hashlink
            , '_blank');
        })
        .catch(error => {
          alert('Ошибка загрузки');
          console.log(error);
          this.dataLoadingError = true;
          this.dataLoading = false;
        });
    },
    getSavedByLinkConfiguration(hashlink) {
      axios.get(
        '/api/configurator/saved/' + hashlink)
        .then(response => {
          this.savedByLinkConfiguration = response.data;
          this.getConfigurationData();
        })
        .catch(error => {
          console.log(error);
          this.dataLoadingError = true;
          this.dataLoading = false;
        });
    }
  },

  computed: {
    visibleParams() {
      if (!this.configuration.hasOwnProperty('params')) {
        return [];
      }
      return this.configuration.params.filter(param => param.visible);
    },
    orderCode() {
      if (!this.configuration.hasOwnProperty('params')) {
        return '';
      }
      this.configuration.orderCode = this.configuration.ordercodetpl;
      this.configuration.params.forEach(param => {
          this.configuration.orderCode = this.configuration.orderCode.replace(`{${param.id}}`, this.getParamCode(param));
      })
      return this.configuration.orderCode;
    },
    savedByUserConfigurationUrl() {
      return location.protocol + '//' + location.host +'/configurator/' + this.configuration.url
        + '?saved=' + this.savedByUserConfiguration.hashlink;
    }
  },

  created() {
    if (this.saved) {
      this.getSavedByLinkConfiguration(this.saved)
    } else {
      this.getConfigurationData();
    }
  }
}
</script>
