"use strict";

const startLineOffset = 80;
const links = [...document.querySelectorAll("#scroll-navbar a")];
let blocks = [];

if (links.length) {
    links.forEach((item) => {
        let blockId = item.href.split('#')[1];
        const block = document.getElementById(blockId);
        if (block) {
            blocks.push(block);
        }
    });

    function scrollListener()
    {
        blocks.forEach((block) => {
            if ((block.getBoundingClientRect().top <= startLineOffset) && (block.getBoundingClientRect().bottom >= startLineOffset)) {
                links.find(link => link.href.includes(block.id)).classList.add('active');
            } else {
                links.find(link => link.href.includes(block.id)).classList.remove('active');
            }
        })
    }

    document.addEventListener('scroll', scrollListener, false);
}
